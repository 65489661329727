<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M169.7,146.5c-3.6-9.4-7.3-18.8-10.9-28.3c-0.5-1.3-1.1-2.6-2-3.7c-4.2-5.1-10.6-5.4-16.3-1l5,11.3   c0.3,0.8,0,1.6-0.8,2c-0.4,0.2-0.8,0.2-1.1,0c-0.4-0.1-0.7-0.4-0.8-0.8l-6.1-13.9c-4.9-3.7-9.6-3.5-14.5,0.6l8.4,19.4   c0.3,0.8,0,1.6-0.8,2c-0.4,0.2-0.8,0.2-1.1,0c-0.4-0.1-0.7-0.4-0.8-0.8L118,111c-4.1-3-8.9-3-13.3-0.1l11.7,27.4   c0.3,0.8,0,1.6-0.8,2c-0.4,0.2-0.8,0.2-1.1,0c-0.4-0.1-0.7-0.4-0.8-0.8l-11.4-26.7c0,0,0,0,0,0c-1.9-4.4-3.6-8.7-5.6-12.9   c-3.1-6.6-10.4-8.9-16.3-5.1c-4.9,3.1-6.4,8.8-3.8,14.8c6.9,15.8,13.9,31.6,20.9,47.4c0.4,0.8,1,1.5,1.5,2.3   c-0.3,0.2-0.5,0.3-0.8,0.5c-7.7-1.7-15.4-3.4-23.2-4.9c-4.6-0.9-8.6,0.5-11.9,3.8c-5.2,5.3-4.2,13,2.3,15.5   c17.5,6.9,35.1,13.8,52.8,20.4c6.9,2.6,14.1,2.3,21-0.4c4.4-1.7,8.6-3.6,12.9-5.5C169.2,181.1,176.3,164,169.7,146.5z"/>
      <path class="st0" d="M93.1,84.1c7.9,3.1,13.1,10.6,13.2,19.1c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5   c-0.1-10.6-6.5-19.9-16.4-23.7c-13.2-5.1-28,1.5-33.2,14.6c-3.8,9.7-1.3,20.7,6.3,27.9c0.2,0.2,0.5,0.4,0.8,0.5   c0.9,0.4,2,0.1,2.7-0.6c1-1,0.9-2.6-0.1-3.5c-6.1-5.8-8.1-14.6-5-22.4C70.6,85.3,82.5,80,93.1,84.1z"/>
      <path class="st0" d="M122.6,101.2c1.4-0.2,2.3-1.4,2.2-2.8c-1.9-14.2-11.3-26.3-24.7-31.5c-20.2-7.8-42.9,2.2-50.8,22.4   c-5.8,15.1-1.7,32.5,10.3,43.3c0.2,0.2,0.5,0.4,0.8,0.5c0.9,0.4,2,0.1,2.8-0.7c0.9-1,0.8-2.6-0.2-3.5c-10.5-9.5-14.1-24.6-9-37.8   c6.8-17.6,26.7-26.4,44.3-19.5c11.7,4.5,19.9,15.1,21.6,27.5C120,100.4,121.2,101.4,122.6,101.2z"/>
      <path class="st0" d="M46.6,191.5c-6.5,0-11.8-5.7-11.8-12.8V21.3c0-7.1,5.3-12.8,11.8-12.8h9.5c1.4,4.1,5.3,7,9.8,7h35.6   c4.6,0,8.4-3,9.8-7h8.8c6.9,0,12.5,6.1,12.5,13.6v81.8c2.4,0.5,4.7,1.5,7,3.1c0,0,0,0,0,0V22.1c0-11.4-8.7-20.6-19.5-20.6H46.6   c-10.3,0-18.8,8.9-18.8,19.8v157.4c0,10.9,8.4,19.8,18.8,19.8h65.2c-5.9-2.2-12-4.5-18.4-7H46.6z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconTouch',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

